* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
  display: grid;
  grid-template-rows: 100px 1fr 75px;
  scroll-behavior: smooth;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.modalOn {
  overflow: hidden;
  height: 100vh;
}

.container80 {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
